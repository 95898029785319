<!--设备公共模块--警报页-->
<template>
  <div
    id="alarm"
    ref="alarm"
    v-loading="loading"
    :element-loading-text="$t('alarm.a0')"
  >
    <!-- 头部查询栏 -->
    <div class="header">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="form_row">
          <div class="form_col">
            <!--站点组联菜单-->
            <a-form-model-item prop="selectOption">
              <a-cascader :options="siteOptions" :placeholder="$t('warningSetting.a1')" v-model="form.selectOption" @change="onChange" @popupVisibleChange="getBaseInfo"/>
            </a-form-model-item>
          </div>  
          <div class="form_col">
            <a-form-model-item>
              <!--选择警报状态-->
              <a-select
                v-model="showContents"
                style="width: 120px"
                @change="showContentsChange"
              >
                <a-select-option value="0">
                  <!--//未确认 未確認 -->
                  {{ $t("alarm.a35") }}
                </a-select-option>
                <a-select-option value="1">
                  <!--//未恢复 -->
                  {{ $t("alarm.a36") }}
                </a-select-option>
                <a-select-option value="2">
                  <!--//未应对 -->
                  {{ $t("alarm.a37") }}
                </a-select-option>
                <a-select-option value="3">
                  <!--//全部 -->
                  {{ $t("alarm.a38") }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="form_col">
            <!--设备名称-->            
            <a-form-model-item>
              <a-input v-model="form.name" :placeholder="$t('alarm.a2')" @change="nameChange"/>
            </a-form-model-item>
          </div>
          <div class="dateContainer">
            <div>
              <a-form-model-item prop="date1">
                  <a-date-picker
                    v-model="form.date1"
                    :disabled-date="disabledStartDate"
                    show-time
                    :allowClear="false"
                    format="YYYY-MM-DD HH:mm:ss"
                    @change="handleCalendarChange"
                    :placeholder="$t('alarm.a4')"
                  />
              </a-form-model-item>
            </div>
            <div class="transition">~</div>
            <div>
              <a-form-model-item prop="date2">
                  <a-date-picker
                    v-model="form.date2"
                    :disabled-date="disabledEndDate"
                    show-time
                    :allowClear="false"
                    format="YYYY-MM-DD HH:mm:ss"
                    @change="handleCalendarChange2"
                    :placeholder="$t('alarm.a5')"
                  />
              </a-form-model-item>
            </div>
          </div>
          <div class="form_col">
            <!--查询按钮-->
            <a-form-model-item>
              <a-button type="primary" @click="inquirePage(1)">{{
                $t("energy.enedata006")
              }}</a-button>
              <!-- <a-button type="primary" @click="inquire">{{
                $t("energy.enedata006")
              }}</a-button> -->
            </a-form-model-item>
          </div>
           <div class="form_col">
            <!--导出CSV-->
            <a-form-model-item>
              <a-button
                type="primary"
                icon="file-text"
                ghost
                :disabled="!enableExport"
                @click="inquireAndExportPage"
                style="margin-right: 20px"
              >
                {{ $t("alarm.a51") }}
              </a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main" v-table-size="tableSize">
      <a-table
        :columns="columns"
        :dataSource="data"
        :scroll="size"
        :pagination="pagination"
        :rowClassName="fontClass"
        
      >
        <template slot="cameraIdList" slot-scope="text, record">
          <a-button v-if="record.cameraIdList.length>0" type="info" icon="video-camera" shape="round" size="small" @click="openVideo(record)"/>
          <span v-else>--</span>
        </template>
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button
                  type="primary"
                  class="table_but"
                  @click="acknowledgement(record)"
                  >{{ $t("alarm.a9") }}</a-button
                ><br />
                <a-button
                  type="primary"
                  class="table_but"
                  v-show="showManual(record)"
                  @click="manualRecovery(record)"
                  >{{ $t("alarm.a10") }}</a-button
                >
                <br v-show="showManual(record)" />
                <a-button
                  type="primary"
                  class="table_but"
                  @click="dispose(record)"
                  >{{ $t("alarm.a11") }}</a-button
                ><br />
                <a-button
                  type="primary"
                  class="table_but"
                  @click="() => annotation(record)"
                  >{{ $t("alarm.a14") }}</a-button
                >
              </template>
              <a>{{ $t("alarm.a15") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    
    <!-- 添加注释 -->
    <a-modal
      :title="$t('alarm.a16')"
      centered
      :visible="visible"
      :closable="false"
      :getContainer="() => this.$refs.alarm"
      @cancel="annotationCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="annotationCancel">
          {{ $t("alarm.a40") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="but_disabled"
          @click="annotationOk"
        >
          {{ $t("alarm.a41") }}
        </a-button>
      </template>
      <div v-loading="annotationLoading" :element-loading-text="$t('alarm.a0')">
        <a-textarea
          v-model="comment"
          :placeholder="$t('alarm.a17')"
          :auto-size="{ minRows: 10, maxRows: 10 }"
          ref="text"
        />
      </div>
    </a-modal>
    <!-- 处理状态变更 -->
    <a-modal
      :title="$t('alarm.a42')"
      centered
      :width="400"
      :visible="dispose_visible"
      :closable="false"
      :getContainer="() => this.$refs.alarm"
      @cancel="disposeCancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="disposeCancel">
          {{ $t("alarm.a40") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="but_disabled"
          @click="disposeOk"
        >
          {{ $t("alarm.a41") }}
        </a-button>
      </template>
      <div v-loading="processingLoading" :element-loading-text="$t('alarm.a0')">
        <div>
          <span>{{ `${$t("alarm.a43")}${correspondenceStatus}` }}</span>
          <span></span>
        </div>
        <br />
        <div>
          <span>{{ $t("alarm.a44") }}</span>
          <a-select v-model="processingStatus" style="width: 120px">
            <a-select-option value="1"> {{ $t("alarm.a37") }} </a-select-option>
            <a-select-option value="2"> {{ $t("alarm.a45") }} </a-select-option>
            <a-select-option value="3"> {{ $t("alarm.a46") }} </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
     <!-- 视频弹框 -->
     <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
  </div>
</template>

<script>
import { csvDownload } from "../../../../utils/utils";
import {
  getTenantList,
  addComment,
  acknowledgement,
  manualRecovery,
  processing,
  singleOperation,//added by li_yj on 2023-3-28单条记录的操作（报警确认，处理，添加注释）
} from "../../../../api/door";
import { getEnergyNotificationList,getNotificationPageList } from "../../../../api/energy";
import moment from "moment";
import cameraDialog from '../../../monitoring/cameraDialog.vue';

export default {
  name: "attendanceRecord",
  props: {
    msg: {
      type: Object,
      default:function(){
				return {};
      }
    },    
  }, 
  data() {
    return {
      baseInfo: this.$store.getters.oneMenu,
      visible: false,
      enableExport:false,//是否允许导出
      siteId:0,      
      showContents: "0",
      notificationStatus: "0",
      correspondenceStatus: "",
      // correspondenceStatusList:[
      //     {id:3,name:'出发'},
      //     {id:4,name:'到达'},
      //     {id:1,name:'处理完成'},
      //     {id:9,name:'上述之外'},
      // ],
      dispose_visible: false,
      form: {
        selectOption:[],
        name: "",
        floor: 0,
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      size: { x: 2000, y: 600 },
      options: [],
      record: "",
      comment: "",
      but_disabled: false,
      row: undefined,
      data: [],
      exportData:[],//added by li_yj on 2022-10-21导出数据（查询全部数据，非分页数据）
      loading: false,
      annotationLoading: false,
      startDate: undefined,
      correspondenceStatus: "",
      processingStatus: "1",
      processingLoading: false,
      rules: {
        selectOption:[
          {
            required: true,
            message: () => {
              return this.$t("alarm.a58");
            },
            trigger: "change",
          },
        ],
        date1: [
          {
            required: true,
            message: () => {
              return this.$t("alarm.a18");
            },
            trigger: "change",
          },
        ],
        date2: [
          {
            required: true,
            message: () => {
              return this.$t("alarm.a18");
            },
            trigger: "change",
          },
        ],
      },
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示30条数据
        showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        current:1,//当前页面
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.current = current;
          this.pagination.pageSize = size;
          this.inquirePage(current);
          console.log("onChange current", current);
        },
      },
      columns: [
        {
          title: "NO",          
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.current - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("alarm.a20"),          
          dataIndex: "date",
          customRender: (text, record, index) => {
            if (record.date == null) {
              return "--";
            } else {
              return record.date;
            }
          },
          width: 150,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a21"),          
          dataIndex: "normalTime",
          customRender: (text, record, index) => {
            if (record.normalTime == null) {
              return "--";
            } else {
              return record.normalTime;
            }
          },
          width: 150,
          ellipsis: true,
        },
        
        {
          title: this.$t("alarm.a23"),          
          dataIndex: "fmcAlarmHistoryType",
          customRender: (text, record, index) => {
            if (record.fmcAlarmHistoryType == null) {
              return "--";
            } else {
              return record.fmcAlarmHistoryType;
            }
          },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a57"),//'警报等级',          
          dataIndex: "alarmPriorityText",
          customRender: (text, record, index) => {
            if (record.alarmPriorityText == null) {
              return "--";
            } else {
              return record.alarmPriorityText;
            }
          },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a28"),          
          dataIndex: "eventMessage",
          customRender: (text, record, index) => {
            if (record.eventMessage == null) {
              return "--";
            } else {
              return record.eventMessage;
            }
          },
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a29"),          
          dataIndex: "correspondenceStatus",
          customRender: (text, record, index) => {
            if (record.correspondenceStatus == null) {
              return "--";
            } else {
              return record.correspondenceStatus;
            }
          },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("energy.enedata375"),          
          dataIndex: "acknowledgementStatus",
          customRender: (text, record, index) => {
            if (record.acknowledgementStatus == null) {
              return "--";
            } else {
              return record.acknowledgementStatus;
            }
          },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a24"),          
          dataIndex: "siteName",
          customRender: (text, record, index) => {
            if (record.siteName == null) {
              return "--";
            } else {
              return record.siteName;
            }
          },
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a25"),          
          dataIndex: "equipmentName1",
          customRender: (text, record, index) => {
            if (record.equipmentName1 == null) {
              return "--";
            } else {
              return record.equipmentName1;
            }
          },
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a26"),          
          dataIndex: "cameraIdList",
          scopedSlots: { customRender: "cameraIdList" },
          // customRender: (text, record, index) => {
          //   if (record.equipmentName == null) {
          //     return "--";
          //   } else {
          //     return record.equipmentName;
          //   }
          // },
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a27"),          
          dataIndex: "equipmentName2",
          customRender: (text, record, index) => {
            if (record.equipmentName2 == null) {
              return "--";
            } else {
              return record.equipmentName2;
            }
          },
          width: 170,
          ellipsis: true,
        },
        // {
        //   title: this.$t("alarm.a52"),//'KPI类型',          
        //   dataIndex: "eventType",
        //   customRender: (text, record, index) => {
        //     if (record.eventType == null) {
        //       return "--";
        //     } else {
        //       return record.eventType;
        //     }
        //   },
        //   width: 100,
        //   ellipsis: true,
        // },
        // {
        //   title: this.$t("alarm.a53"),//'当前值',          
        //   dataIndex: "eventValue",
        //   customRender: (text, record, index) => {
        //     if (record.eventValue == null) {
        //       return "--";
        //     } else {
        //       return record.eventValue;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        // {
        //   title: this.$t("alarm.a54"),//'限值',          
        //   dataIndex: "exceededLimited",
        //   customRender: (text, record, index) => {
        //     if (record.exceededLimited == null) {
        //       return "--";
        //     } else {
        //       return record.exceededLimited;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        // {
        //   title: this.$t("alarm.a55"),//'死区',          
        //   dataIndex: "deadband",
        //   customRender: (text, record, index) => {
        //     if (record.deadband == null) {
        //       return "--";
        //     } else {
        //       return record.deadband;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        // {
        //   title: this.$t("alarm.a56"),//'占比（%）',          
        //   dataIndex: "rate",
        //   customRender: (text, record, index) => {
        //     if (record.rate == null) {
        //       return "--";
        //     } else {
        //       return record.rate;
        //     }
        //   },
        //   width: 100,
        //   align:'right',
        //   ellipsis: false,
        // },
        {
          title: this.$t("alarm.a33"),          
          dataIndex: "description",
          customRender: (text, record, index) => {
            if (record.description == null) {
              return "--";
            } else {
              return record.description;
            }
          },
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t("alarm.a34"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
      serviceType: 0,
      cameraId:0,
      vdialog:false,
      vmode:'1',
      starDate:new moment().startOf('day'),
      endDate:new moment(),
    };
  },
  // 获取警报页楼层/租户选择列表
  created() {
    if(this.$route.query.id){
        getTenantList(this.$route.query.id)
        .then((res) => {
          let { data } = res;
          // console.log(data);
          for (let i = data.length - 1; i > -1; i--) {
            this.options.push({ value: data[i].id, name: data[i].name });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  activated() {
    console.log("siteType>>>>", this.$store.getters.siteType);
    let siteType = this.$store.getters.siteType;
    // if(siteType==='WATER' || siteType==='ELECTRICITY'){
    //this.serviceType = 2;

    // }
  },
  watch: {
    pluginsDataChange:{
      handler(newValue,oldValue){
        
      },
      deep:true,
    },
  }, 
  computed: {
    pluginsDataChange () {
      console.log("alarm computed pluginsDataChange:",this.msg); 
      if(Object.keys(this.msg).length!=0){ 
          //参数赋值        
          this.siteId=this.msg.siteId;
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = 30;
          this.showContents="3";//全部
          let reg = new RegExp("/","g");//g,表示全部替换。
          let dateStr=this.msg.date.replace(reg,"-")+" "+this.msg.time;
          this.form.date1=new moment(dateStr).subtract(1, 'second');
          this.form.date2=new moment(dateStr).add(1, 'second');
          this.form.name="";

          //判断站点服务类型
          this.serviceType=0;
          if(this.baseInfo!=null &&this.baseInfo.length>0){
            for(let i=0;i<this.baseInfo.length;i++){
              if(this.baseInfo[i].id==this.msg.clientId){
                let siteGrList =this.baseInfo[i].siteGrList;
                if(siteGrList!=null&&siteGrList.length>0){                       
                  for(let j=0;j<siteGrList.length;j++){
                    if(siteGrList[j].id==this.msg.siteGrId){
                      let siteList= siteGrList[j].siteList;
                      if(siteList!=null&&siteList.length>0){
                        for(let k=0;k<siteList.length;k++){
                          if(siteList[k].id==this.msg.siteId){
                            this.serviceType=siteList[k].contractType;
                            this.form.selectOption.length=0;
                            this.form.selectOption.push(this.msg.clientId);
                            this.form.selectOption.push(this.msg.siteGrId);
                            this.form.selectOption.push(siteList[k].contractType+","+this.msg.siteId);
                            break;
                          }
                        } 
                      } 
                      break;                      
                    }
                  }
                }
                break;
              }                              
            }
          }
          //查询
          this.inquireWithPar();  
        } 
      const dataStr=JSON.stringify(this.msg); 
      return dataStr;
    },
    siteOptions() {          
      let siteGrList = [];
      let siteList=[];     
      let options = [];     
      console.log("equipment baseInfo:", this.baseInfo);       
      if(this.baseInfo!=null &&this.baseInfo.length>0){
        for(let i=0;i<this.baseInfo.length;i++){
          let client = {
                    value: this.baseInfo[i].id,
                    label: this.baseInfo[i].clientName,
                    children: [],
                  };
          options.push(client);
          siteGrList = this.baseInfo[i].siteGrList; 
          if(siteGrList!=null&&siteGrList.length>0){                       
            for(let j=0;j<siteGrList.length;j++){
              let siteGr = {
                    value: siteGrList[j].id,
                    label: siteGrList[j].name,
                    children: []
                  };
              client.children.push(siteGr);
              siteList= siteGrList[j].siteList;
              if(siteList!=null&&siteList.length>0){
                for(let k=0;k<siteList.length;k++){
                  let site = {
                    value: siteList[k].contractType+","+siteList[k].id,
                    label: siteList[k].name,
                    children: []
                  };
                  siteGr.children.push(site);
                }
              }
            }            
          }          
        }
      }
      console.log("equipment options:", options);
      return options;
    },
    // 判断需要查询的条件
    conditionNo: function () {
      if (this.form.name == "") {
        return 0;
      } else {
        return 2;
      }
    },    
  },
  methods: {
    getBaseInfo(value){
      const baseInfo = this.baseInfo
      if(value){
        if(baseInfo.length == 0){
          this.baseInfo = this.$store.getters.oneMenu
        }
      }
    },
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("alarm");
      if(indexDom){
          const height = indexDom.getElementsByClassName("main")[0].clientHeight;
          const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
          const tableHeight = height - title - 64
          this.size.y = tableHeight;
      }
    },
    moment,    
    onChange(value,selectedOptions) {
      console.log("value:",value);
      console.log("selectOption:",this.selectOption);
      this.enableExport=false;//是否允许导出
      if(selectedOptions!=null){
        let customerName = selectedOptions[0].label;
        let builName=selectedOptions[2].label;
      }
      if(value!=null){
        this.clientId = value[0];
        this.siteGrId = value[1];
        let arr = value[2].split(',');
        this.serviceType = arr[0]-0;
        this.siteId = arr[1]-0;
      }      
    },
    // 表格自适应方法
    // tableSize() {
    //   let height = this.$refs.main.clientHeight;
    //   console.log("this.$refs.main.clientHeight:",height);
    //   if(height<600)//最小600
    //     height=600;
    //   let tableHeight = height - 100;
    //   this.size.y = tableHeight;
    // },
    showContentsChange(e) {
      this.enableExport=false;//是否允许导出
      this.notificationStatus = "";
      this.correspondenceStatus = "";
      if (e == "1") {
        this.notificationStatus = e;
      } else if (e == "2") {
        this.notificationStatus = e;
        this.correspondenceStatus = "0";
      } else if (e == "3") {
        this.notificationStatus = e;
      } else {
        this.notificationStatus = "";
      }
    },
     // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD");
      const date2 = this.form.date2.format("YYYYMMDD");
      const name =this.$t("alarm.a7");
      const fileName = `${date1}~${date2}${name}`;  
      //转换列表数据对象   
      csvDownload(this.exportData, this.columns, fileName);
    },
    // 条件查询方法(分页)
    inquireWithPar(){
      this.loading = true;
      let param = {
        siteId: this.siteId,
        serviceType: this.serviceType,
        hisType: 1,            
        startDateTime: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
        endDateTime: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),            
        pageNum: 1,
        pageSize: this.pagination.pageSize, 
        alarmStatus:parseInt(this.showContents),                       
        equipmentName1:this.form.name,
      };
      console.log("getNotificationPageList param", param);
      getNotificationPageList(param)
        .then((res) => {              
          this.loading = false;
          let { data } = res.data;
          console.log("getNotificationPageList res", res);  
          this.pagination.total = res.data.total;     
          this.data = res.data.list;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // 条件查询方法(分页)
    inquirePage(page){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.tableSize()
          console.log("this.pagination.current", this.pagination.current);
          this.pagination.current=page;
          this.loading = true;
          let param = {
            siteId: this.siteId,
            serviceType: this.serviceType,
            hisType: 1,            
            startDateTime: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDateTime: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),            
            pageNum: page,
            pageSize: this.pagination.pageSize, 
            alarmStatus:parseInt(this.showContents),                       
            equipmentName1:this.form.name,
          };
          console.log("getNotificationPageList param", param);
          getNotificationPageList(param)
            .then((res) => {              
              this.loading = false;
              let { data } = res.data;
              console.log("getNotificationPageList res", res);  
              this.pagination.total = res.data.total; 
              this.data = res.data.list;
              this.enableExport=true;//是否允许导出
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },

    // 条件查询导出CSV方法
    inquireAndExport() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let param = {
            action: 1,
            clientId: this.clientId,
            sitegrId: this.siteGrId,
            siteId: this.siteId,
            notificationStatus: this.notificationStatus, //this.showContents,
            deviceName: this.form.name,
            tenantId: 0, //this.options[this.form.floor].value
            star: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            end: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
            signalName: "",
            correspondenceStatus: this.correspondenceStatus, //alarmStatus
            serviceType: this.serviceType,
          };
          // console.log(data);
          console.log("GetEnergyNotificationList param", param);
          getEnergyNotificationList(param)
            .then((res) => {
              console.log("GetEnergyNotificationList result", res);
              // console.log("data", data);
              this.loading = false;
              let { data } = res.data;
              this.exportData = data;
              //导出数据
              this.csvExport();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 条件查询导出CSV方法(分页接口)
    inquireAndExportPage() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let param = {
            siteId: this.siteId,
            serviceType: this.serviceType,
            hisType: 1,            
            startDateTime: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDateTime: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),            
            pageNum: 1,
            pageSize: this.pagination.total, 
            alarmStatus:parseInt(this.showContents),                       
            equipmentName1:this.form.name,
          };          
          console.log("getNotificationPageList param", param);
          getNotificationPageList(param)
            .then((res) => {              
              this.loading = false;
              let { data } = res.data;
              console.log("getNotificationPageList res", res);  
              this.exportData = res.data.list;
              //导出数据
              this.csvExport();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 警报确认 modified by li_yj on 2023-03-28
    acknowledgement(value) {
      this.$confirm({
        title: this.$t("alarm.a47"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const param = {
            date: value.date,
            smartGatewayId:value.smartGatewayId,
            cursor:value.cursor,
            category:value.category,
            hisOperType:3,//1：注释；2：处理；3：警报确认
            description:"",//注释内容
            happoKind:0,//1：未处理；2：处理中；3：已处理
          };
          console.log("singleOperation param",param);
          singleOperation(param)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.inquirePage(1);
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
            
        },
      });
    },
    // 手动恢复
    manualRecovery(value) {
      this.$confirm({
        title: this.$t("alarm.a48"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const data = {
            params: value.param,
          };
          // console.log(data);
          manualRecovery(data)
            .then((res) => {
              this.loading = false;
              // console.log(res);
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.inquirePage(1);
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        },
      });
    },
    // 应对（处理）按钮
    dispose(value) {
      this.dispose_visible = true;
      //this.correspondenceStatus = value.correspondenceStatus;
      this.record = value; //modified by li_yj on 2023-03-28
    },
    // 应对（处理）的确定按钮 modified by li_yj on 2023-03-28
    disposeOk() {
      this.processingLoading = true;
      const param = {
        date: this.record .date,
        smartGatewayId:this.record .smartGatewayId,
        cursor:this.record .cursor,
        category:this.record .category,
        hisOperType:2,//1：注释；2：处理；3：警报确认
        description:"",//注释内容
        happoKind:this.processingStatus,//1：未处理；2：处理中；3：已处理
      };
      console.log("singleOperation param",param);
      singleOperation(param)
        .then((res) => {
          this.processingLoading = false;
          // console.log(res);
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
            this.dispose_visible = false;
            this.inquirePage(1);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.processingLoading = false;
          console.log(err);
        });
    },
    // 应对（处理）的取消按钮
    disposeCancel() {
      this.dispose_visible = false;
    },
    // 打开添加注释的对话框并且获取对应ID值
    annotation(value) {
      this.visible = true;
      this.record = value; //modified by li_yj on 2023-03-28
      this.value = value.description;
      setTimeout(() => {
        this.$refs.text.focus();
      }, 5);
      // console.log(key);
    },
    // 添加注释对话框的确定按钮 //modified by li_yj on 2023-03-28
    annotationOk() {
      this.but_disabled = true;
      this.annotationLoading = true;
      const param = {
        date: this.record.date,
        smartGatewayId:this.record .smartGatewayId,
        cursor:this.record .cursor,
        category:this.record .category,
        hisOperType:1,//1：注释；2：处理；3：警报确认
        description:this.comment,//注释内容
        // happoKind:this.processingStatus,//1：未处理；2：处理中；3：已处理
      };
      console.log("singleOperation param",param);
      singleOperation(param)
        .then((res) => {
          // console.log(res);
          this.but_disabled = false;
          this.annotationLoading = false;
          if (res.errorCode == "00") {
            const newData = [...this.data];
            const target = newData.filter(
              (item) => this.params === item.param
            )[0];
            if (target) {
              target["description"] = this.comment;
            }

            this.visible = false;
            this.comment = "";
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.but_disabled = false;
          this.annotationLoading = false;
          console.log(err);
        });
    },
    // 添加注释对话框的取消按钮
    annotationCancel(e) {
      this.visible = false;
      this.comment = "";
    },
    //设备名称变化回调
    nameChange(value){
      this.enableExport=false;//是否允许导出
    },
     // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      this.enableExport=false;//是否允许导出
      if(moment(this.form.date2).isBefore(val))
        this.form.date2 = moment(val).endOf("day");
    },
     // 结束日期/时间选择变化回调
    handleCalendarChange2(value, mode) {
      this.enableExport=false;//是否允许导出
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const nowValue = moment();
      if (!startValue || !nowValue) {
        return false;
      }
      // 禁用大于计算机日期的日期值
      return startValue.valueOf() > nowValue.valueOf();
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      const _value = JSON.stringify(startValue);
      const value = JSON.parse(_value);
      const nowValue = moment().endOf("day");
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        endValue.valueOf() > nowValue.valueOf() ||
        moment(value).add(1, "months").valueOf() <= endValue.valueOf()
      );
    },
    // 表格字体恢复时间改变
    fontClass(record, index) {
      // if (record.normalTime == null) {
      //   return "red";
      // } else {
      //   return "";
      // }
      //modified by li_yj on 2022-9-21 绿色：已处理；红色：严重警报；黑色：普通；
      // console.log("fontClass record:",record);
      // if(record.normalTime&&record.normalTime!="-"){
      if(record.eventStatus){//modified by li_yj on 2022-10-13
        return "green";
      }else if(record.alterType==1){
        return "red";
      }else{
        return "";
      }
    },
    // 手动恢复按钮根据条件隐藏
    showManual(value) {
      if(value.param){
        let tmp = value.param.split(",");
        if (tmp[9] == "51" || tmp[9] == "13" || tmp[9] == "181") {
          if (tmp[11] == "9") {
            return true;
          }
        } else {
          return false;
        }
      }
    },
    openVideo(record){
      console.log(record);
     
      this.cameraId = parseInt(record.cameraIdList[0]);
      let mstar = new moment(record.date).subtract(15, 'seconds');
      let mend = new moment(record.date).add(15, 'seconds');
      if(record.normalTime && '-' != record.normalTime){
        mend = new moment(record.normalTime).add(15, 'seconds');
      }
      
      this.starDate = mstar;
      this.endDate  = mend;
      this.vmode = '0';
      this.vdialog = true;

    },
  },
  components:{
    "camera-dialog":cameraDialog,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#alarm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.header {
  min-width: 850px;
  padding: 20px 20px 0px 20px;
  overflow: hidden;
}
.main {
  height: calc(100% - 84px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.dateContainer{
  display: flex;
  margin-right: 15px;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
}
.transition{
  font-size: 18px;
  padding: 6px 10px 0px 10px;
}
.fool {
  width: 100px;
  height: 10px;
}
.table_but {
  margin: 5px 0px;
}
div /deep/.red {
  color: red;
}
div /deep/ .green{
  color:green;
}
</style>